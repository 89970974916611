import React from "react";
import SEO from "../components/seo";
import HeadingWithSub from "../components/HeadingWithSub";
import styled from "@emotion/styled";
import HighlightedRow from "../components/HighlightedRow";
import ParentRouteHandler from "../components/ParentRouteHandler";

const GeneralQuestionsKids = ({ location }) => {
  const Container = styled.div`
    margin-top: 23px;
  `;

  const QuestionsWrapper = styled.div`
    margin: 20px auto;
  `;

  const Text = styled.p`
    font-size: 16px;
  `;

  return (
    <>
      <SEO title="Questions like these can get you and your kids talking:" />
      {location.state &&
      location.state.originalPath &&
      location.state.originalPath === "home" ? (
        <ParentRouteHandler title="Home" path="/" />
      ) : (
        <ParentRouteHandler title="For Families" path="/for-families" />
      )}

      <HeadingWithSub
        main={`Questions like these can get you and your kids talking:`}
        subTitle={``}
      />
      <Container>
        <Text>
          The best way for families to experience art is to imagine everything
          as fair game. There’s no observation too small or too obvious; there’s
          nothing too silly to be discussed.
        </Text>
        <Text>
          Let your instincts and your child’s curiosity guide you. Children
          often pick up on details that adults may not notice. Let their fresh
          eyes and excitement influence how you look at art, too!
        </Text>

        <QuestionsWrapper>
          <HighlightedRow text="What do you like or dislike about this artwork?" />

          <HighlightedRow text="What emotions do you feel when you are looking at this artwork?" />

          <HighlightedRow text="What do you imagine the artist was thinking about when they made this?" />

          <HighlightedRow text="Does this artwork remind you of anything in your life?" />

          <HighlightedRow text="What things do you have in your home that you see in the museum?" />

          <HighlightedRow text="Compare and contrast two artworks near each other. How are they alike or different?" />

          <HighlightedRow text="How many things can you find within an artwork in two minutes?" />
        </QuestionsWrapper>

        <p>
          When in doubt, ask for help! You can ask a Visitor Experience Team
          Member, text us at 213-232-6280, or chat with us live. Access live
          chat through the icon in the bottom right corner of your screen.
        </p>
      </Container>
    </>
  );
};

export default GeneralQuestionsKids;
