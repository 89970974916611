import React from 'react';
import styled from '@emotion/styled';

const HighlightedRow = ({ text }) => {
  const Row = styled.div`
    background-color: #d6001c;
    margin-bottom: 8px;
    padding: 10px 14px;
  `;

  const Text = styled.p`
    color: #fff;
    font-family: 'JeanLuc';
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
  `;

  return (
    <Row>
      <Text>{text}</Text>
    </Row>
  );
};

export default HighlightedRow;
